<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_warehouse') }}</span>
      <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save_and_close') }}</el-button>
      <el-button 
        type="primary"
        size="small"
        class="mr-1"
        @click="submit(false)"
      >{{ $t('message.save_and_create_new') }}</el-button>
    <el-button @click="close()">{{ $t('message.close') }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" :rules="rules" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="20">
           <el-col :sm="12">
             <el-form-item :label="$t('message.department')" prop="branch">
                  <branch :placeholder="$t('message.department')" v-model="form.branch_id" :branch_id="form.branch_id" size="mini"></branch>
              </el-form-item>
          </el-col>

          <el-col :sm="12">
            <el-form-item :label="columns.name.title">
              <el-input v-model="form.name" :placeholder="columns.name.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import drawerChild from "@/utils/mixins/drawer-child";
import branch from '@/components/selects/branch';
import form from "@/utils/mixins/form";

export default {
  mixins: [
    form,
    drawerChild
  ],
  components:{
    branch
  },
  data(){
    return {
      loadingButton: false,
      loadingData: false,
    }
  },
  computed: {
    ...mapGetters({
      columns: 'warehouses/columns',
      model: 'warehouses/model',
      rules: 'warehouses/rules'
    })
  },
  methods: {
    ...mapActions({
      save: "warehouses/store",
      update: "warehouses/update",
      show: "warehouses/show",
      empty: "warehouses/empty"
    }),
    afterOpened(){
        this.form = JSON.parse( JSON.stringify(this.model) );
    },
    afterClosed(){
      this.empty()
    },
    submit(close = true){
        this.$refs['form'].validate((valid) => {
            if (valid){
                this.loadingButton = true;
                this.save(this.form)
                    .then(res => {
                        this.loadingButton = false;
                        this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                        this.parent().listChanged()
                        if (close == true) {
                            this.close();
                        } else {
                            this.empty()
                        }
                    })
                    .catch(err => {
                        this.loadingButton = false;
                        this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                    });
            }
        });
    }
  }
};
</script>
